import { createBrowserRouter } from "react-router-dom";
import Sqribble from "../pages/sqribble";
import Home from "../pages";
import SqribbleMain from "../pages/sqribble/main";
import VertShock from "../pages/vert-shock";
import FemiPro from "../pages/femipro";
import NanoDefensePro from "../pages/nanoDefensePro";
import EmperorsVigorTonic from "../pages/emperorsVigorTonic";
import Vizonic from "../pages/vizonic";
import Sonixine from "../pages/sonixine";

export default function Routes() {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/sqribble", element: <Sqribble /> },
    { path: "/sqribble/main", element: <SqribbleMain /> },
    { path: "/vert-shock", element: <VertShock /> },
    { path: "/femipro", element: <FemiPro /> },
    { path: "/nano-defense-pro", element: <NanoDefensePro /> },
    { path: "/emperors-vigor-tonic", element: <EmperorsVigorTonic /> },
    { path: "/vizonic", element: <Vizonic /> },
    { path: "/sonixine", element: <Sonixine /> },
  ]);
  return router;
}
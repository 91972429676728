import Modal from "../../components/Modal";

export default function Sonixine() {
  const linkAffiliate = "https://tl-track.com/tracker/uhbH";

  return (
    <>
      <div className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sonixine/mobile.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sonixine/desktop.png)] bg-contain lg:bg-cover`}></div>
      <Modal 
        link={linkAffiliate} 
        lang={"it"} 
      />
    </>
  )
}